import React from 'react';
import { styled } from '@mui/material/styles';
import { APP_GREEN_COLOR, APP_RED_COLOR } from '../../constants/const';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@mui/material';

const PREFIX = 'StatusBar';

const classes = {
  barContainer: `${PREFIX}-barContainer`,
  barContent: `${PREFIX}-barContent`,
  filterView: `${PREFIX}-filterView`,
  checkboxContainer: `${PREFIX}-checkboxContainer`,
  blockIndicator: `${PREFIX}-blockIndicator`,
  checkboxLabel: `${PREFIX}-checkboxLabel`,
};

const Root = styled('div')(() => ({
  [`& .${classes.barContainer}`]: {
    display: 'flex',
    height: '25px',
    background: '#555',
  },

  [`& .${classes.barContent}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    cursor: 'pointer',
    fontSize: '12px',
  },

  [`& .${classes.filterView}`]: {
    marginTop: '5px',
  },

  [`& .${classes.checkboxContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    margin: '15px 0',
  },

  [`& .${classes.blockIndicator}`]: {
    width: '18px',
    height: '18px',
    margin: '0px 7px 0 3px',
  },

  [`& .${classes.checkboxLabel}`]: {
    cursor: 'pointer',
    fontSize: '12px',
  },
}));

const statusColors = {
  inWarranty: APP_GREEN_COLOR,
  outOfWarranty: APP_RED_COLOR,
  healthy: APP_GREEN_COLOR,
  warning: '#FDC110',
  last30_backup: '#FDC110',
  no_backup: APP_RED_COLOR,
  green: APP_GREEN_COLOR,
  orange: '#FDC110',
  red: APP_RED_COLOR,
  grey: '#555',
  active: APP_GREEN_COLOR,
  expired: APP_RED_COLOR,
  no_data: '#555',
};

export const statusTitle = {
  inWarranty: 'In Warranty',
  outOfWarranty: 'Out Of Warranty',
  healthy: 'Backup in past 7 days',
  last30_backup: 'Backup in past 30 days',
  no_backup: 'No backup in past 30 days',
  warning: 'Warning',
  backupError: 'Fail',
  green: 'Online',
  orange: 'Warning',
  red: 'Fail',
  grey: 'Offline (Safely Shutdown)',
  offline: 'Offline (Safely Shutdown)',
  off: 'Offline',
  active: 'Active',
  expired: 'Expired',
  no_data: 'No Data',
};
const StatusBar = ({ data, filterByStatus, setFilterByStatus, filterName, showFilter }) => {
  const total = Object.values(data).reduce((acc, curr) => acc + curr, 0);
  const { t } = useTranslation();

  const handleChange = (checked, status) => {
    // Toggles the presence of itemName in the specified statusType array
    setFilterByStatus(prevFilters => {
      // Copy the current state to avoid direct mutation
      const updatedFilters = { ...prevFilters };

      // If checked, add itemName to the specified statusType array if not already present
      if (checked) {
        updatedFilters[filterName] = [...updatedFilters[filterName], status];
      } else {
        // If unchecked, remove itemName from the array
        updatedFilters[filterName] = updatedFilters[filterName].filter(item => item !== status);
      }

      return updatedFilters;
    });
  };

  return (
    <Root>
      <div className={classes.barContainer}>
        {Object.entries(data).map(([status, value], index) => (
          <Tooltip title={t(`device_${status}`)} key={status}>
            <div
              key={index}
              className={classes.barContent + ` status-${status}`}
              style={{
                flex: total ? value / total : null,
                backgroundColor: statusColors[status],
                display: value === 0 ? 'none' : 'inherit',
              }}
            >
              {value}
            </div>
          </Tooltip>
        ))}
      </div>
      {showFilter && (
        <div className={classes.filterView}>
          {Object.entries(data).map(([status, value], index) => (
            <div className={classes.checkboxContainer} key={index + '_filter'}>
              <Checkbox
                checked={filterByStatus[filterName].includes(status)}
                onChange={ev => handleChange(ev.target.checked, status)}
                style={{
                  padding: 0,
                }}
                name="filter-checkbox"
              />
              <div
                className={`${classes.blockIndicator}`}
                style={{
                  backgroundColor: statusColors[status],
                }}
                onClick={() => handleChange(!filterByStatus[filterName].includes(status), status)} // Simulate a change event
              />
              <div
                className={`${classes.checkboxLabel}`}
                onClick={() => handleChange(!filterByStatus[filterName].includes(status), status)} // Simulate a change event
              >
                {t(`device_${status}`)}
              </div>
            </div>
          ))}
        </div>
      )}
    </Root>
  );
};

export default StatusBar;
